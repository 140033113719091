.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* すべてのブラウザでスクロールバーを非表示にする */
.hide-scrollbar::-webkit-scrollbar,
.hide-scrollbar::-moz-scrollbar,
.hide-scrollbar::-ms-scrollbar {
  display: none;
}

/* WebKitベースのブラウザでスクロールバーを非表示にする */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Firefoxでスクロールバーを非表示にする */
.hide-scrollbar {
  scrollbar-width: none;
}

/* Internet ExplorerおよびEdgeでスクロールバーを非表示にする */
.hide-scrollbar {
  -ms-overflow-style: none;
}

aside.EmojiPickerReact.epr-main {
  border-radius: none !important;
}

.container {
  flex-wrap: wrap;
}

.item {
  flex: 0 1 auto;
  min-width: 100px;
  overflow: hidden;
}

@media (max-width: 550px) {
  .item {
    display: none;
  }
}